@import '../theme/style.scss';

:root {
    // Breckpoints
    --hf-tablet-breckpoint: 910px;
    --hf-mobile-breckpoint: 410px;

    // Main color
    --hf-main-color: #379AE6;

    // Background color
    --hf-primary-bg: #FFFFFF;
    --hf-secondary-bg: #F5F9FD;
    --hf-grey-bg: #F1F1F1;
    --hf-disable-bg: #F5FAFF;
    --hf-validation-bg: #FFB82E;
    --hf-error-bg: #F93232;
    --hf-success-bg: #439F6E;

    // Border color
    --hf-border-size: 2px;
    --hf-radius-size: 5px;
    --hf-border-default: #E2E2E2;
    --hf-border-focus: #F6D172;

    // Text color
    --hf-primary-text: #212529;
    --hf-secondary-text: #466171;
    --hf-theme-color: #379AE6;
    --hf-white-text: #FFFFFF;
    --hf-grey-text: #9CB7C9;
    --hf-light-grey-text: #CED4DA;
    --hf-dark-grey-text: #476172;
    --hf-green-text: #009D19;
    --hf-disable-text: #BACCDA;
    --hf-link-text: #0404BC;
    --hf-validation-text: #FFB82E;
    --hf-error-text: #F93232;
    --hf-success-text: #439F6E;

    // Button styles
    --hf-button-primary-bg-default: #379AE6;
    --hf-button-primary-text-default: #FFFFFF;
    --hf-button-primary-bg-hover: #177ECC;
    --hf-button-primary-text-hover: #FFFFFF;
    --hf-button-primary-bg-focused: #177ECC;
    --hf-button-primary-stroke-focused: #F6D172;
    --hf-button-primary-text-focused: #FFFFFF;
    --hf-button-primary-bg-active: #1276C2;
    --hf-button-primary-text-active: #FFFFFF;
    --hf-button-primary-bg-click: #0561A7;
    --hf-button-primary-text-click: #FFFFFF;
    --hf-button-primary-bg-disable: #E6F4FF;
    --hf-button-primary-text-disable: #ADCFE9;
    --hf-button-border-radius: 5px;
    --hf-button-secondary-bg-default: #FFFFFF;
    --hf-button-secondary-stroke-default: #379AE6;
    --hf-button-secondary-text-default: #379AE6;
    --hf-button-secondary-bg-hover: #F6FBFF;
    --hf-button-secondary-stroke-hover: #177ECC;
    --hf-button-secondary-text-hover: #177ECC;
    --hf-button-secondary-bg-focused: #F6FBFF;
    --hf-button-secondary-stroke-focused: #F6D172;
    --hf-button-secondary-text-focused: #177ECC;
    --hf-button-secondary-bg-active: #F6FBFF;
    --hf-button-secondary-stroke-active: #379AE6;
    --hf-button-secondary-text-active: #379AE6;
    --hf-button-secondary-bg-click: #F6FBFF;
    --hf-button-secondary-text-click: #379AE6;
    --hf-button-secondary-bg-disable: #E6F4FF;
    --hf-button-secondary-text-disable: #ADCFE9;
    --hf-button-border-width: 1px;
    --hf-button-tertiary-bg-default: #FFFFFF;
    --hf-button-tertiary-text-default: #379AE6;
    --hf-button-tertiary-bg-hover: #F6FBFF;
    --hf-button-tertiary-text-hover: #379AE6;
    --hf-button-tertiary-bg-focused: #F6FBFF;
    --hf-button-tertiary-stroke-focused: #F6D172;
    --hf-button-tertiary-text-focused: #379AE6;
    --hf-button-tertiary-bg-active: #E6F4FF;
    --hf-button-tertiary-text-active: #379AE6;
    --hf-button-tertiary-bg-click: #D1E9FB;
    --hf-button-tertiary-text-click: #379AE6;
    --hf-button-tertiary-bg-disable: #E6F4FF;
    --hf-button-tertiary-text-disable: #ADCFE9;
    --hf-danger-button-color: #CA4A4F;

    // Card styles
    --hf-card-primary-right-padding: 16px;
    --hf-card-primary-left-padding: 16px;
    --hf-card-primary-top-padding: 16px;
    --hf-card-primary-bottom-padding: 16px;
    --hf-card-tertiary-right-padding: 14px;
    --hf-card-tertiary-left-padding: 14px;
    --hf-card-tertiary-top-padding: 14px;
    --hf-card-tertiary-bottom-padding: 14px;
    --hf-card-tertiary-mobile-right-padding: 10px;
    --hf-card-tertiary-mobile-left-padding: 10px;
    --hf-card-tertiary-mobile-top-padding: 10px;
    --hf-card-tertiary-mobile-bottom-padding: 10px;
    --hf-card-primary-bg: #FFFFFF;
    --hf-card-primary-title: #212529;
    --hf-card-primary-border-radius: 5px;
    --hf-card-primary-border-width: 1px;
    --hf-card-primary-border: #E2E2E2;
    --hf-card-secondary-bg: white;
    --hf-card-secondary-title: #212529;
    --hf-card-secondary-border-radius: 5px;
    --hf-card-secondary-border-width: 1px;
    --hf-card-secondary-border: #E2E2E2;
    --hf-card-space-between-elements: 10px;
    --hf-card-tertiary-bg: #F5F9FD;
    --hf-card-tertiary-border-radius: 5px;
    --hf-card-tertiary-border-width: 1px;
    --hf-card-tertiary-border: #EAECF0;

    // Input styles
    --hf-input-small-right-padding: 8px;
    --hf-input-small-left-padding: 8px;
    --hf-input-small-top-padding: 4px;
    --hf-input-small-bottom-padding: 4px;
    --hf-input-small-border-radius: 5px;
    --hf-input-small-border-width: 1px;
    --hf-input-medium-right-padding: 8px;
    --hf-input-medium-left-padding: 8px;
    --hf-input-medium-top-padding: 6px;
    --hf-input-medium-bottom-padding: 6px;
    --hf-input-medium-border-radius: 5px;
    --hf-input-medium-border-width: 2px;
    --hf-input-icon-spacing: 10px;
    --hf-input-border: #E2E2E2;
    --hf-input-bg: #FFFFFF;
    --hf-input-title: #9FADB7;
    --hf-input-placeholder: #9EACB6;
    --hf-input-value: #476172;
    --hf-input-description: #212529;

    //Texteditor style
    --hf-texteditor-border-radius: 5px;
    --hf-texteditor-border-width: 2px;
    --hf-texteditor-border: #E2E2E2;

    // Model styles
    --hf-model-right-padding: 15px;
    --hf-model-left-padding: 15px;
    --hf-model-top-padding: 15px;
    --hf-model-bottom-padding: 15px;
    --hf-model-backdrop-size: 5px;
    --hf-model-bg: #FFFFFF;
    --hf-model-border-radius: 5px;
    --hf-model-border-width: 2px;
    --hf-model-border: #E2E2E2;

    // Table styles
    --hf-table-data-right-padding: 5px;
    --hf-table-data-left-padding: 5px;
    --hf-table-data-top-padding: 5px;
    --hf-table-data-bottom-padding: 5px;

    // Primary Table styles
    --hf-primary-table-data-right-padding: 5px;
    --hf-primary-table-data-left-padding: 5px;
    --hf-primary-table-data-top-padding: 5px;
    --hf-primary-table-data-bottom-padding: 5px;
    --hf-primary-table-header-right-padding: 5px;
    --hf-primary-table-header-left-padding: 5px;
    --hf-primary-table-header-top-padding: 5px;
    --hf-primary-table-header-bottom-padding: 5px;
    --hf-primary-table-border-radius: 5px;
    --hf-primary-table-border-width: 1px;
    --hf-primary-table-border-color: #F8F8F8;
    --hf-primary-table-header-bg: #F1F1F1;
    --hf-primary-table-tr-odd-bg: #FFFFFF;
    --hf-primary-table-tr-odd-color: #212529;
    --hf-primary-table-tr-even-bg: #F8F8F8;
    --hf-primary-table-tr-even-color: #212529;
    --hf-primary-table-tr-hover-bg: #EAEBEE;

    // Label styles
    --hf-label-right-padding: 5px;
    --hf-label-left-padding: 5px;
    --hf-label-top-padding: 5px;
    --hf-label-bottom-padding: 5px;
    --hf-label-border-radius: 5px;
    --hf-label-border-width: 0px;
    --hf-label-border-color: #FFFFFF;
    --hf-label-success-bg: #439F6E;
    --hf-label-success-color: #FFFFFF;
    --hf-label-error-bg: #F93232;
    --hf-label-error-color: #FFFFFF;
    --hf-label-warning-bg: #FFB82E;
    --hf-label-warning-color: #FFFFFF;
    --hf-label-info-bg: #379AE6;
    --hf-label-info-color: #FFFFFF;

    // Toast styles
    --hf-toast-right-padding: 10px;
    --hf-toast-left-padding: 10px;
    --hf-toast-top-padding: 10px;
    --hf-toast-bottom-padding: 10px;
    --hf-toast-border-radius: 5px;
    // --hf-toast-warning-bg: #FFF8e0;
    // --hf-toast-error-bg: #FEEBE5;
    // --hf-toast-success-bg: #E9F8F3;
    // --hf-toast-info-bg: #EEF6FE;

    // -------new toast design
    --hf-toast-bg: #FFFFFF;
    --hf-toast-border-width: 2px;
    --hf-toast-border: #E2E2E2;

    // Radio Styles
    --hf-radio-bg: #FFFFFF;
    --hf-radio-bg-disabled: #F5FAFF;
    --hf-radio-border: #b2b2b2;
    --hf-radio-border-hover: #379AE6;
    --hf-radio-border-checked: #379AE6;
    --hf-radio-inner-circle-checked: #379AE6;
    --hf-radio-inner-circle-disabled-checked: #BACCDA;
    --hf-radio-focus: #F6D172;
    --hf-radio-text-disabled: #BACCDA;
    --hf-radio-container-margin: 5px 0;
    --hf-radio-and-label-gap: 5px;
    --hf-radio-border-radius: 50%;
    --hf-radio-border-size: 1px;
    --hf-radio-outline-size-focus: 2px;
    --hf-radio-size-large: 24px;
    --hf-radio-size-medium: 18px;
    --hf-radio-size-small: 12px;

    // Checkbox styles
    --hf-checkbox-bg-default: #379AE6;
    --hf-checkbox-bg-hover: #379AE6;
    --hf-checkbox-bg-disable: #F5FAFF;
    --hf-checkbox-bg-checked-disabled: #BACCDA;
    --hf-checkbox-border-default: #E2E2E2;
    --hf-checkbox-border-hover: #379AE6;
    --hf-checkbox-border-focused: #379AE6;
    --hf-checkbox-border-width: 1px;
    --hf-checkbox-stroke-focused: #F6D172;
    --hf-checkbox-border-radius: 5px;
    --hf-checkbox-label-disabled: #ADCFE9;

    // Select styles
    --hf-select-right-padding: 10px;
    --hf-select-left-padding: 10px;
    --hf-select-top-padding: 10px;
    --hf-select-bottom-padding: 10px;
    --hf-select-border-radius: 5px;
    --hf-select-border-width: 2px;
    --hf-select-border-color: #E2E2E2;
    --hf-select-bg: #FFFFFF;
    --hf-select-color: #212529;
    --hf-select-font-size: 18px;

    // Anchor styles
    --hf-default-anchor-color: #212529;
    --hf-default-anchor-hover-color: #0C75BB;
    --hf-secondary-anchor-color: #379AE6;
    --hf-secondary-anchor-hover-color: #0404BC;

    // Fonts
    --hf-regular-font: 'hk-regular';
    --hf-bold-font: 'hk-bold';

    // Tab styles
    --hf-active-tab-border-width: 2px;
    --hf-active-tab-border-color: #379AE6;
    --hf-active-tab-color: #379AE6;
}