.hf-heading-1 {
    font-size: 56px;
    line-height: 61.6px;
    font-weight: 700;
}

.hf-heading-2 {
    font-size: 48px;
    line-height: 57.6px;
    font-weight: 700;
}

.hf-heading-3 {
    font-size: 32px;
    line-height: 38.4px;
    font-weight: 500;
}

.hf-heading-4 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
}

.hf-heading-5 {
    font-size: 22px;
    line-height: 26.4px;
    font-weight: 500;
}

.hf-heading-6 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.hf-heading-7 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    height: 22px;
}

.hf-body-1 {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 400;
}

.hf-body-2 {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;
}

.hf-body-3 {
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
}

.hf-body-4 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.hf-button-text-extralarge {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.hf-button-text-large {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.hf-button-text-medium {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.hf-button-text-small {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}

.hf-button-text-extrasmall {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.hf-input-placeholder {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.hf-input-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.hf-input-description {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

@media (max-width: 910px) {
    .hf-heading-1 {
        font-size: 48px;
        line-height: 52.8px;
        font-weight: 700;
    }

    .hf-heading-2 {
        font-size: 32px;
        line-height: 38.4px;
        font-weight: 700;
    }

    .hf-heading-3 {
        font-size: 24px;
        line-height: 28.8px;
        font-weight: 700;
    }

    .hf-heading-4 {
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 700;
    }

    .hf-heading-5 {
        font-size: 16px;
        line-height: 19.2px;
        font-weight: 700;
    }

    .hf-body-1 {
        font-size: 16px;
        line-height: 20.8px;
        font-weight: 400;
    }

    .hf-body-2 {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;
    }
}

@media (max-width: 500px) {
    .hf-heading-1 {
        font-size: 24px;
        line-height: 28.8px;
        font-weight: 700;
    }

    .hf-heading-2 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }

    .hf-heading-3 {
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 700;
    }

    .hf-heading-4 {
        font-size: 16px;
        line-height: 19.2px;
        font-weight: 700;
    }

    .hf-heading-5 {
        // design changes
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 700;
    }

    .hf-body-1 {
        font-size: 16px;
        line-height: 20.8px;
        font-weight: 400;
    }

    .hf-body-2 {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;
    }
}

.hf-primary-text-color {
    color: var(--hf-primary-text);
}

.hf-secondary-text-color {
    color: var(--hf-secondary-text);
}

.hf-white-text-color {
    color: var(--hf-white-text);
}

.hf-grey-text-color {
    color: var(--hf-grey-text);
}

.hf-light-grey-text-color {
    color: var(--hf-light-grey-text);
}

.hf-dark-grey-text-color {
    color: var(--hf-dark-grey-text);
}

.hf-green-text-color {
    color: var(--hf-green-text);
}

.hf-disable-text-color {
    color: var(--hf-disable-text);
}

.hf-link-text-color {
    color: var(--hf-link-text);
}

.hf-validation-text-color {
    color: var(--hf-validation-text);
}

.hf-error-text-color {
    color: var(--hf-error-text);
}

.hf-theme-text-color {
    color: var(--hf-theme-color);
}

.hf-success-text-color {
    color: var(--hf-success-text);
}

.hf-anchor-color {
    color: var(--hf-secondary-anchor-color);
}

.hf-anchor-color:hover {
    color: var(--hf-secondary-anchor-hover-color);
}

.hf-card-title-extralarge {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
}

.hf-card-title-large {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
}

.hf-card-title-medium {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.hf-card-title-small {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.hf-card-title-extrasmall {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.hf-input-title-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

::placeholder {
    // font size for placeholder
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.hf-input-small-value-text {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    height: 22px;
}

.hf-input-medium-value-text {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    height: 24px;
}

.hf-input-description-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.hf-model-title-extralarge {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
}

.hf-model-title-large {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
}

.hf-model-title-medium {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.hf-model-title-small {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.hf-model-title-extrasmall {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.hf-toast-title-text {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
}

.hf-toast-content-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
}

.hf-checkbox-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.hf-radio-label-large {
    font-size: 18px;
    line-height: 18px;
}

.hf-radio-label-medium {
    font-size: 14px;
    line-height: 14px;
}

.hf-radio-label-small {
    font-size: 12px;
    line-height: 12px;
}

.hf-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
