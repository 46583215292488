lib-heatfleet-header {
    display: block;
    padding-top: var(--status-bar-height);
    background: var(--hf-primary-bg);
}

app-payment-header {
    display: block;
    padding-top: var(--status-bar-height);
    background: var(--hf-primary-bg);
}

hf-toast {
    padding-top: var(--status-bar-height);
}

.skeleton-box {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
        animation: shimmer 1s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
