:root {
    --hf-main-color: #304198;
    --hf-button-primary-bg-default: #304198;
    --hf-button-primary-bg-hover: #304198;
    --hf-button-primary-bg-focused: #304198;
    --hf-button-primary-bg-active: #304198;
    --hf-button-primary-bg-click: #304198;
    --hf-button-secondary-stroke-default: #304198;
    --hf-button-secondary-text-default: #304198;
    --hf-button-secondary-stroke-hover: #304198;
    --hf-button-secondary-text-hover: #304198;
    --hf-button-secondary-text-focused: #304198;
    --hf-button-secondary-stroke-active: #304198;
    --hf-button-secondary-text-active: #304198;
    --hf-button-secondary-text-click: #304198;
    --hf-button-tertiary-text-default: #304198;
    --hf-button-tertiary-text-hover: #304198;
    --hf-button-tertiary-text-focused: #304198;
    --hf-button-tertiary-text-active: #304198;
    --hf-button-tertiary-text-click: #304198;
    --hf-radio-border-hover: #304198;
    --hf-radio-border-checked: #304198;
    --hf-radio-inner-circle-checked: #304198;
    --hf-checkbox-bg-default: #304198;
    --hf-checkbox-bg-hover: #304198;
    --hf-checkbox-border-hover: #304198;
    --hf-checkbox-border-focused: #304198;
}