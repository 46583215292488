@import "./typography.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: var(--hf-regular-font), Arial;
}

hf-button {
    display: block;
    height: fit-content;
    width: 100%;
}

hf-input {
    display: block;
    width: 100%;
}

hf-card {
    display: block;
    height: fit-content;
    width: 100%;
}

hf-model {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}

hf-toast {
    display: block;
    position: fixed;
    // top: 10px;
    // right: 10px;
    top: 50%; // added for center align
    left: 50%; // added for center align
    transform: translate(-50%, -50%); // added for center align
    z-index: 10000;
}

hf-radio {
    display: block;
    height: fit-content;
    width: 100%;
}

hf-checkbox {
    display: block;
    height: fit-content;
    width: 100%;
}

hf-select {
    display: block;
    width: 100%;
}

.hf-main-bg {
    background-color: var(--hf-main-color);
}

.hf-primary-bg {
    background-color: var(--hf-primary-bg);
}

.hf-secondary-bg {
    background-color: var(--hf-secondary-bg);
}

.hf-gray-bg {
    background-color: var(--hf-grey-bg);
}

.hf-disable-bg {
    background-color: var(--hf-disable-bg);
}

.hf-validation-bg {
    background-color: var(--hf-validation-bg);
}

.hf-error-bg {
    background-color: var(--hf-error-bg);
}

.hf-success-bg {
    background-color: var(--hf-success-bg);
}

.hf-main-color {
    color: var(--hf-main-color);
}

.hf-border-default-color {
    border-color: var(--hf-border-default);
}

.hf-border-radius {
    border-radius: var(--hf-radius-size);
}

.hf-border-size {
    border-width: var(--hf-border-size);
}

.hf-border-focus {
    border-color: var(--hf-border-focus);
}

.hf-light-font {
    font-family: var(--hf-regular-font), Arial;
    font-weight: normal;
}

.hf-dark-font {
    font-family: var(--hf-bold-font), Arial;
    font-weight: bold;
}

.hf-disable-scroll {
    overflow: hidden;
}

.hf-table {
    width: 100%;
}

.hf-table th {
    padding: var(--hf-table-data-top-padding) var(--hf-table-data-right-padding) var(--hf-table-data-bottom-padding) var(--hf-table-data-left-padding);
}

.hf-table td {
    padding: var(--hf-table-data-top-padding) var(--hf-table-data-right-padding) var(--hf-table-data-bottom-padding) var(--hf-table-data-left-padding);
}

.hf-table-primary {
    width: 100%;
    border: var(--hf-primary-table-border-width) solid var(--hf-primary-table-border-color);
    border-radius: var(--hf-primary-table-border-radius);
    border-collapse: collapse;
}

.hf-table-primary th {
    padding: var(--hf-primary-table-header-top-padding) var(--hf-primary-table-header-right-padding) var(--hf-primary-table-header-bottom-padding) var(--hf-primary-table-header-left-padding);
    background-color: var(--hf-primary-table-header-bg);
}

.hf-table-primary td {
    padding: var(--hf-primary-table-data-top-padding) var(--hf-primary-table-data-right-padding) var(--hf-primary-table-data-bottom-padding) var(--hf-primary-table-data-left-padding);
}

.hf-table-primary tr:nth-child(odd) {
    background-color: var(--hf-primary-table-tr-odd-bg);
    color: var(--hf-primary-table-tr-odd-color);
}

.hf-table-primary tr:nth-child(even) {
    background-color: var(--hf-primary-table-tr-even-bg);
    color: var(--hf-primary-table-tr-even-color);
}

.hf-table-primary tr:hover {
    background-color: var(--hf-primary-table-tr-hover-bg);
}

.hf-label {
    padding: var(--hf-label-top-padding) var(--hf-label-right-padding) var(--hf-label-bottom-padding) var(--hf-label-left-padding);
    border: var(--hf-label-border-width) solid var(--hf-label-border-color);
    border-radius: var(--hf-label-border-radius);
}

.hf-label-success {
    background-color: var(--hf-label-success-bg);
    color: var(--hf-label-success-color);
}

.hf-label-error {
    background-color: var(--hf-label-error-bg);
    color: var(--hf-label-error-color);
}

.hf-label-warning {
    background-color: var(--hf-label-warning-bg);
    color: var(--hf-label-warning-color);
}

.hf-label-info {
    background-color: var(--hf-label-info-bg);
    color: var(--hf-label-info-color);
}

.hf-shadow-1 {
    box-shadow: 0px 4px 4px 0px #4896DA29;
}

.hf-shadow-2 {
    box-shadow: 0px 4px 8px 0px #4896DA29;
}

.hf-shadow-3 {
    box-shadow: 0px 8px 16px 0px #4896DA1F;
}

.hf-shadow-4 {
    box-shadow: 0px 16px 24px 0px #4896DA1F;
}

.hf-text-editor {
    width: 100%;
    border: var(--hf-texteditor-border-width) solid var(--hf-texteditor-border);
    border-radius: var(--hf-texteditor-border-radius);
}

.hf-text-editor ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.hf-text-editor ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.hf-text-editor strong {
    font-family: var(--hf-bold-font);
}

.hf-text-editor .NgxEditor {
    border: 0px !important;
    border-top: var(--hf-texteditor-border-width) solid var(--hf-texteditor-border) !important;
    border-radius: 0px !important;
}

.hf-text-editor .NgxEditor__MenuItem--Button {
    background-color: var(--hf-button-primary-bg-default) !important;
    color: var(--hf-button-primary-text-default) !important;
    border: none !important;
    border-radius: var(--hf-button-border-radius) !important;
    padding: 5px 10px;
    cursor: pointer;
}

.hf-text-editor .NgxEditor__MenuItem {
    cursor: pointer !important;
}

.hf-active-tab {
    border-bottom: var(--hf-active-tab-border-width) solid var(--hf-active-tab-border-color);
    color: var(--hf-active-tab-color);
}

.hf-skeleton-box {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    border-radius: var(--hf-radius-size);

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
        animation: shimmer 1s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}